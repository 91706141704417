
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "undewriter-policies-list",
  components: {},
  data() {
    return {
      policies: [
        {
          client: "",
          reference: "",
          policyNumber: "",
          type: "",
          premium: 0,
          hasPolicyDocument: "",
          businessTypeShortCode: "",
          items: [],
          startDate: "",
          publicId: "",
        },
      ],
    };
  },
  setup() {
    const {
      reinitialization,
      useReusableNavigation,
      displayDatatable,
    } = ResusableFunctions();

    onMounted(() => {
      setCurrentPageTitle("Policy Listing");
      displayDatatable(["basicExample"]);
    });

    return {
      useReusableNavigation,
      reinitialization,
    };
  },
  created() {
    this.getpolicies();

    //Set page title
    document.title = "Policy Listing | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    getpolicies() {
      //Fetch underwriter dashboard data
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.UNDERWRITERS_POLICIES_ROUTE)
          .then(({ data }) => {
            this.policies = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    gotoUrl(event) {
      this.$router.push(event);
    },
    hasCompletedCertification(vehicles) {
      const allEqual = (arr) =>
        arr.every((value) => {
          return value ? true : false;
        });

      // Array to be checked
      const arrayValues = [] as any;

      if (Array.isArray(vehicles)) {
        vehicles.every((item) => {
          arrayValues.push(item.isApproved);
        });
      }

      // Compare the values in the one-dimensional array
      return allEqual(arrayValues);
    },
  },
});
